/* ==================================================================================================================================
* Dashboard WhatsApp WEB - Gerenciador de mensagens
* ==================================================================================================================================
* Copyright (C) 2010 - 2023
* Programador: Fabio P. vilas Boas 
*
*  - fabio.2705@hotmail.com
*  - multi-clinicas@hotmail.com
*  - multiclinicas.suporte@gmail.com
*  - Skype: Multi Clinicas
*
* Modulo TICKET´S, Painel Esquerdo HEADER
*
* Projeto Original
* https://github.com/canove/whaticket
* Coded by WhatsApp WEB
* ----------------------------------------------------------------------------------------------------------------------------------
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
import React, { useContext, useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { IconButton, Tooltip /* Avatar ,Divider*/ } from "@material-ui/core";

import AddIcCall from "@material-ui/icons/AddIcCall";
import BackspaceIcon from "@material-ui/icons/Backspace";
import MessageIcon from "@material-ui/icons/Message";
import ForumIcon from "@material-ui/icons/ReplyAll";
import AvatarImage from "../../assets/logomarca.png";

const useStyles = makeStyles((theme) => ({
  	ticketsWrapper: {
  		top: 0,
    	display: "flex",
    	height: "100%",
    	overflow: "hidden",
    	position: "relative",
    	flexDirection: "column",
		borderRight: 0,
    	borderTopRightRadius: 0,
    	borderBottomRightRadius: 0,
		transition: "0.3s",
  	},
  	tabsHeader: {
    	flex: "none",
    	backgroundColor: "#ffffff",
		borderBottom: "1px solid whitesmoke",
  	},
  	settingsIcon: {
   	alignSelf: "center",
    	marginLeft: "auto",
    	padding: 8,
  	},
  	tab: {
 		minWidth: "12%",
 		width: 120,
      textTransform: "capitalize",
      fontFamily: "Tahoma",
      fontSize: "97%",
      border: "0px",
  	},
   ticketOptionsBoxTop: {
   	display: "flex",
    	justifyContent: "space-between",
    	alignItems: "center",
    	backgroundColor: "whitesmoke",
		backgroundImage: "linear-gradient(to right, #fdfdfd,#fdfdfd,#fafafa,#fafafa)",
		padding: theme.spacing(1,2,0,2),
		borderRight: 0,
  	},
   ticketOptionsBox: {
   	display: "flex",
    	justifyContent: "space-between",
    	alignItems: "center",
    	backgroundColor: "whitesmoke",
		backgroundImage: "linear-gradient(to right, #fdfdfd,#fdfdfd,#fafafa,#fafafa)",
		padding: theme.spacing(0.2,1),
  	},
  	serachInputWrapper: {
   	flex: 1,
    	display: "flex",
    	backgroundColor: "#ffffff",
    	borderRadius: 15,
    	width: "100%",
    	margin: 3,
    	border: "1px solid #e1e1e3",
    	"&:hover": {
    		border: "1px solid #9eaccb",
    	},

		padding: theme.spacing(1,2,0,2),
  	},
  	searchIcon: {
   	color: "royalblue",
    	marginLeft: 6,
    	marginRight: 6,
    	alignSelf: "center",
    	border: "none",
  	},
  	searchInput: {
   	flex: 1,
    	border: 0,
      fontSize: "14px",
    	cursor: "pointer",
   },
  	closeIcon: {
    	justifyContent: "flex-end",
		color:"#cccccc",
    	"&:hover": {
			color:"#777888",
    	},
		cursor:"pointer",
		fontSize:"1.4em",
	},
   contactButtom: {
		margin:2,
    	boxShadow:"0 1px 1px 0 gainsboro",
		padding:10,
	},
	resolvedButtom: {
		margin:2,
    	boxShadow:"0 1px 1px 0 gainsboro",
		padding:10,
	},
  	badge: {
  		marginTop: "0px",
   	marginRight: "-10px",
		textTransform: "capitalize",
      fontSize: "100%",
      fontFamily: "Tahoma",
  	},
  	show: {
   	display: "block",
  	},
  	hide: {
   	display: "none !important",
  	},
   word: {
      width: "115px",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
   },
	textLink: {
		float:"left",
		marginTop:"10px",
		padding: 5,
		marginLeft:"8px",
		fontFamily: "Arial",
		fontSize:"16px",
		color:"#174ea8",
		fontWeight:"normal",
	},
	messageAtendimento: {
		backgroundColor:"#18181b",
		color:"white",
		textAlign:"center",
		fontFamily: "Arial",
		fontSize:"15px",
		padding: 2,
		borderRight: "1px solid #ffffff",
	},
}));

const TicketsManager = () => {
  	const classes = useStyles();
  	const [searchParam, setSearchParam] = useState("");
  	const [tab, setTab] = useState("open");
  	const [tabOpen] = useState("open");
  	const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  	const [showAllTickets, setShowAllTickets] = useState(false);
  	const searchInputRef = useRef();
  	const { user } = useContext(AuthContext);

  	const [pendingCount, setOpenCount] = useState(0);
  	const userQueueIds = user.queues.map((q) => q.id);
  	const [selectedQueueIds] = useState(userQueueIds || []);
	
	useEffect(() => {
 		if(user.profile.toUpperCase() === "ADMIN") {
   		setShowAllTickets(true);
 		}
 		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

  	useEffect(() => {
   	if(tab === "search") {
      	searchInputRef.current.focus();
    	}
 		// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, [tab]);
	
  	const handleSearch = (e) => {
   	const searchedTerm = e.target.value.toLowerCase();
    	setSearchParam(searchedTerm);
    	if(searchedTerm === "") {
      	setTab("open");
    	} else if(tab !== "search") {
      	setTab("search");
    	}
  	};

  	const handleClose = (e) => {
  		//document.getElementById('search').value = "";
  		//document.getElementById('resolvedButtom').style.display = 'block';
  		//document.getElementById('resolvedExitButtom').style.display = 'none';
      setTab("open");
	   return;
  	};
  	const resolvedTicket = (e) => {
  		//document.getElementById('resolvedButtom').style.display = 'none';
  		//document.getElementById('resolvedExitButtom').style.display = 'block';
      setTab("closed");
	   return;
  	};
  	
  	const applyPanelStyle = (status) => {
   	if(tabOpen !== status) {
      	return { width: 0, height: 0 };
    	}
  	};

  	return (
      <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      	<NewTicketModal 
      		modalOpen={newTicketModalOpen} 
      		onClose={(e) => setNewTicketModalOpen(false)}>
      	</NewTicketModal>
         {/** Opções de gerenciamento **/}
      	<Paper elevation={0} className={classes.ticketOptionsBoxTop}>
         	{/** Painel LEFT Superior **/}
      		<div style={{display:"flex",width:"70%",justifyContent:"flex-start"}}>
					{/** <Avatar src={AvatarImage} style={{width:45,height:45,margin:2,objectFit:"cover"}} /> **/}
					<img src={AvatarImage} aria-hidden 
						style={{ 
							width: "45px",
							height: "45px",
							boxShadow:"0 1px 1px 0 gainsboro",
							padding: 5,
   						objectFit: "scale-down",
   						borderRadius: "100%"
					}} alt={"logo"} />
          		<span className={classes.textLink}>
               	{i18n.t(user.name)} 
          		</span>
      		</div>
      		<div style={{display:"flex",width:"30%",justifyContent:"flex-end"}}>
       			<Tooltip arrow title="Mensagens resolvidas">
	            	<IconButton id="resolvedButtom" color="primary" className={classes.resolvedButtom}
	              		onClick={() => resolvedTicket()}>
	          			<MessageIcon style={{fontSize:"1em",color:"#1c8b18"}} />
	            	</IconButton>
					</Tooltip>
       			<Tooltip arrow title="Retornar as Mensagens">
	            	<IconButton id="resolvedExitButtom" color="primary" className={classes.resolvedButtom}
	              		onClick={handleClose}>
	          			<ForumIcon style={{fontSize:"1em",color:"#212125"}} />
	            	</IconButton>
					</Tooltip>
       			<Tooltip arrow title="Pesquisar / Cadastrar Contato (Iniciar uma NOVA Conversa)">
	            	<IconButton color="primary" className={classes.contactButtom}
	              		onClick={() => setNewTicketModalOpen(true)}>
	          			<AddIcCall style={{fontSize:"1em"}} />
	            	</IconButton>
					</Tooltip>
      		</div>
      	</Paper>
         {/** Search **/}
      	<Paper square elevation={0} className={classes.ticketOptionsBox}>
       		<div className={classes.serachInputWrapper}>
            	<SearchIcon className={classes.searchIcon} />
        			<InputBase type="search" autoFocus id="search" className={classes.searchInput}
              		onChange={handleSearch}  
              		inputRef={searchInputRef}
              		placeholder={i18n.t("Pesquisar nas mensagens ou pelo contato")}
            	/>
       			<Tooltip arrow title="Limpar pesquisa">
            		<BackspaceIcon onClick={handleClose} className={classes.closeIcon} />
					</Tooltip>
       		</div>
            <Badge overlap="rectangular" style={{display:"none"}}
               badgeContent={pendingCount}>
            </Badge>
      	</Paper>
    		{/** Atendimentos resolvidos **/}
   		<TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
     			<TicketsList status="closed"
       			showAll={true}
       			selectedQueueIds={selectedQueueIds}>
         	</TicketsList>
      	</TabPanel>
      	
    		{/** Gerencia o Atendimento **/}
    		<TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
    			{/** Aguardando Atendimento **/}
        		<div className={classes.messageAtendimento}>Aguardando Atendimento</div>
	        	<TicketsList status="pending"
	          	showAll={true}
	          	selectedQueueIds={selectedQueueIds}>
         	</TicketsList>
    			{/** Em Atendimento **/}
        		<div className={classes.messageAtendimento}>Atendimento em curso</div>
       		<TicketsList status="open"
         		showAll={showAllTickets}
         		selectedQueueIds={selectedQueueIds}
         		updateCount={(val) => setOpenCount(val)} style={applyPanelStyle("open")}>
         	</TicketsList>
      	</TabPanel>
      	
    		{/** Lista a pesquisa **/}
      	<TabPanel value={tab} name="search" className={classes.ticketsWrapper}>
        		<TicketsList
          		showAll={true}
          		searchParam={searchParam}
          		selectedQueueIds={selectedQueueIds}>
          	</TicketsList>
      	</TabPanel>
    	</Paper>
  	);
};
export default TicketsManager;